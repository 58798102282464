import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Image, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import CustomContainer from 'components/shared/Container';
import { CUT_DRY_CLIENT_ID } from 'lib/constants';
import FloatingButton from 'components/shared/FloatingButton';
import { GLOBAL_PRODUCT_PDP } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import NutritionSection from 'components/upb_viewer/pdp_components/NutritionSection';
import pointerOnHover from 'lib/css/pointerOnHover';
import ProductDescriptionSection from 'components/upb_viewer/pdp_components/ProductDescriptionSection';
import ProductImagesSection from 'components/upb_viewer/pdp_components/images_section/ProductImagesSection';
import ProductHeaderSection from 'components/upb_viewer/pdp_components/ProductHeaderSection';
import ProductOverviewSection from 'components/upb_viewer/pdp_components/ProductOverviewSection';
import ProductIdentifiersSection from 'components/upb_viewer/pdp_components/ProductIdentifiersSection';
import { ProductType } from 'lib/enums/ProductType';
import PurveyorDetailsSection from 'components/upb_viewer/pdp_components/PurveyorDetailsSection';
import SpecificationsSection from 'components/upb_viewer/pdp_components/SpecificationsSection';
import SupportTicketsCreateModal from 'components/upb_viewer/SupportTicketsCreateModal';
import TaxanomySection from 'components/upb_viewer/pdp_components/TaxanomySection';
import TipsAndHandlingSection from 'components/upb_viewer/pdp_components/TipsAndHandlingSection';
import useGet from 'lib/hooks/useGet';
import WeightAndDimensionsSection from 'components/upb_viewer/pdp_components/WeightAndDimensionsSection';
import { GlobalProductsDetailContext } from 'components/global_products/detail_view/GlobalProductsDetailContainer';

const sections = {
  OVERVIEW: 'Overview',
  DESCRIPTION: 'Description',
  INGREDIENTS: 'Ingredients',
  SPECIFICATIONS: 'Specifications',
  TOP_OFF: 'Top-Off',
  WEIGHTS_AND_DIMENSIONS: 'Weight & Dimensions',
  TIPS_AND_HANDLING: 'Tips & Handling',
  NUTRITION_FACTS: 'Nutrition Facts',
  CLAIMS: 'Claims',
  ALLERGENS: 'Allergens',
  PRODUCT_IDENTIFIERS: 'Product Identifiers',
  CHILD_NUTRITION_FACTS: 'Child Nutrition Facts',
  ADDITIONAL_DOCUMENTS: 'Additional Documents',
  TAXONOMIES: 'Taxonomies',
};

function UPBProductDetailsPage({ propProductId, productType, hideHeader = false }) {
  const { productId: urlProductId, productType: productTypeToGet } = useParams();
  const [showSupportTicketCreateModal, setShowSupportTicketCreateModal] = useState(false);
  const productId = propProductId || urlProductId;
  const history = useHistory();
  const location = useLocation();
  const { setCndProductScore } = useContext(GlobalProductsDetailContext);

  const typeOfProduct = productType || productTypeToGet;

  const {
    data: { data: product = {} } = {},
    loading,
    error,
  } = useGet(
    GLOBAL_PRODUCT_PDP,
    { product_id: productId, product_type: typeOfProduct, client_id: CUT_DRY_CLIENT_ID },
    !productId,
  );

  useEffect(() => {
    if (product && setCndProductScore) {
      setCndProductScore(product.cd_product_score);
    }
    // eslint-disable-next-line
  }, [product]);

  const overviewSectionData = useMemo(
    () => product.sections?.find(section => section.name === sections.OVERVIEW),
    [product.sections],
  );

  const descriptionSectionData = useMemo(
    () => product.sections?.find(section => section.name === sections.DESCRIPTION),
    [product.sections],
  );

  const ingredients = useMemo(() => {
    const ingredientSection = product.sections?.find(
      section => section.name === sections.INGREDIENTS,
    );
    if (!ingredientSection) {
      return null;
    }
    return ingredientSection.attributes?.find(
      attribute => attribute.attribute_name === 'ingredients_statement',
    )?.attribute_value;
  }, [product.sections]);

  const specifications = useMemo(() => {
    const specificationsSection = product.sections?.find(
      section => section.name === sections.SPECIFICATIONS,
    );
    if (specificationsSection) {
      return specificationsSection.attributes;
    }
    return [];
  }, [product.sections]);

  const topOff = useMemo(() => {
    const topOffSection = product.sections?.find(section => section.name === sections.TOP_OFF);
    if (topOffSection) {
      return topOffSection.attributes;
    }
    return [];
  }, [product.sections]);

  const weightAndDimensions = useMemo(() => {
    const weightAndDimensionsSection = product.sections?.find(
      section => section.name === sections.WEIGHTS_AND_DIMENSIONS,
    );
    if (weightAndDimensionsSection) {
      return weightAndDimensionsSection.attributes;
    }
    return [];
  }, [product.sections]);

  const storageInstructions = useMemo(() => {
    const storageInstructionsSection = product.sections?.find(
      section => section.name === sections.TIPS_AND_HANDLING,
    );
    if (storageInstructionsSection) {
      return storageInstructionsSection.attributes;
    }
    return [];
  }, [product.sections]);

  const nutritionAttributes = useMemo(
    () => product.sections?.find(section => section.name === sections.NUTRITION_FACTS),
    [product.sections],
  );

  const claims = useMemo(() => {
    const claimsSection = product.sections?.find(section => section.name === sections.CLAIMS);
    if (claimsSection) {
      return claimsSection.attributes.map(attr => attr.attribute_value);
    }
    return [];
  }, [product.sections]);

  const allergens = useMemo(() => {
    const allergensSection = product.sections?.find(section => section.name === sections.ALLERGENS);
    if (allergensSection) {
      return allergensSection.attributes;
    }
    return [];
  }, [product.sections]);

  const productIdentifiers = useMemo(() => {
    const productIdentifiersSection = product.sections?.find(
      section => section.name === sections.PRODUCT_IDENTIFIERS,
    );
    if (productIdentifiersSection) {
      return productIdentifiersSection.attributes;
    }
    return [];
  }, [product.sections]);

  const childNutritionFacts = useMemo(() => {
    const childNutritionFactsSection = product.sections?.find(
      section => section.name === sections.CHILD_NUTRITION_FACTS,
    );
    if (childNutritionFactsSection) {
      return childNutritionFactsSection.attributes;
    }
    return [];
  }, [product.sections]);

  const additionalDocuments = useMemo(() => {
    const additionalDocumentsSection = product.sections?.find(
      section => section.name === sections.ADDITIONAL_DOCUMENTS,
    );
    if (additionalDocumentsSection) {
      return additionalDocumentsSection.attributes;
    }
    return [];
  }, [product.sections]);

  const taxonomies = useMemo(
    () => product.sections?.find(section => section.name === sections.TAXONOMIES),
    [product.sections],
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !product) {
    return null;
  }

  return (
    <CustomContainer>
      {typeOfProduct !== ProductType.GLOBAL ? (
        <FloatingButton onClick={() => setShowSupportTicketCreateModal(true)} text="Report issue" />
      ) : null}
      {!hideHeader && (
        <h3 className="font-weight-bold">
          <FontAwesomeIcon
            className={classNames(pointerOnHover, 'mr-2')}
            icon={faArrowCircleLeft}
            onClick={() => history.goBack()}
          />
          UPB View
        </h3>
      )}

      <div className="px-5 py-3 container-fluid">
        <Row className={css(styles.detailedContainer)}>
          <Col md={5}>
            <div className={classNames(css(styles.imageContainer), 'pr-5')}>
              <Image src={product.propagated_thumbnail_image_url} fluid />
              <ProductImagesSection images={product.product_images} />
            </div>
          </Col>
          <Col md={7}>
            <div className={classNames(css(styles.contentContainer), 'pl-3')}>
              <ProductHeaderSection
                product={product}
                productType={typeOfProduct}
                showGMV={location.pathname.includes('upb-pdp')}
              />
              <ProductOverviewSection attributes={overviewSectionData?.attributes} />
              <ProductDescriptionSection
                attributes={descriptionSectionData?.attributes}
                additionalDocuments={additionalDocuments}
              />
              <NutritionSection
                ingredients={ingredients}
                allergens={allergens}
                claims={claims}
                nutritionFacts={nutritionAttributes?.attributes}
                childNutritionFacts={childNutritionFacts}
              />
              <WeightAndDimensionsSection dimensions={weightAndDimensions} />
              <SpecificationsSection specifications={specifications} topOffAttributes={topOff} />
              <TipsAndHandlingSection storageInstructions={storageInstructions} />
              <TaxanomySection taxanomies={taxonomies?.attributes} />
              <ProductIdentifiersSection productIdentifiers={productIdentifiers} />
              <PurveyorDetailsSection brand={product?.brand} manufacturer={product?.manufacturer} />
            </div>
          </Col>
        </Row>
      </div>
      {showSupportTicketCreateModal && (
        <SupportTicketsCreateModal
          onHide={() => setShowSupportTicketCreateModal(false)}
          product={product}
          typeOfProduct={typeOfProduct}
        />
      )}
    </CustomContainer>
  );
}

UPBProductDetailsPage.propTypes = {
  propProductId: PropTypes.number,
  productType: PropTypes.string,
  hideHeader: PropTypes.bool,
};

const styles = StyleSheet.create({
  detailedContainer: {
    display: 'flex',
  },
  imageContainer: {
    flex: '0 0 auto',
    position: 'sticky',
    top: 50,
  },
  contentContainer: {
    background: '#fff',
    minHeight: '50vh',
    flex: 1,
    overflowY: 'auto',
  },
});

export default UPBProductDetailsPage;
